<template>
  <div v-if="product && product.InventoryStatus == 'OUT_OF_STOCK' && product.IsSubSkuBundle == 0 && Status == 'ACTIVE'" class="inventory-status out-of-stock">{{ $t.getTranslation("LBL_OUT_OF_STOCK") }}</div>
  <div v-if="product && product.InventoryStatus == 'IN_STOCK' && product.IsSubSkuBundle == 0 && Status == 'ACTIVE'" class="inventory-status in-stock">{{ $t.getTranslation("LBL_IN_STOCK") }}</div>
  <div v-if="product && product.InventoryStatus == 'LOW_STOCK' && product.IsSubSkuBundle == 0 && pStatus == 'ACTIVE'" class="inventory-status low-stock">{{ $t.getTranslation("LOW_STOCK") }}</div>

  <!-- //this is for sub skus -->
  <div v-if="product && product.IsSubSkuBundle > 0 && !(Status == 'ACTIVE')" class="inventory-status in-stock">{{ $t.getTranslation("LBL_SOLD_PER_BUNDLE") }}</div>
  <div v-if="product && product.IsSubSkuBundle > 0 && Status == 'ACTIVE' && product.InventoryStatus == 'IN_STOCK'" class="inventory-status in-stock">{{ $t.getTranslation("LBL_IN_STOCK") }}</div>
  <div v-if="product && product.IsSubSkuBundle > 0 && Status == 'ACTIVE' && product.InventoryStatus == 'OUT_OF_STOCK'" class="inventory-status out-of-stock">{{ $t.getTranslation("LBL_OUT_OF_STOCK") }}</div>
  <div v-if="product && product.IsSubSkuBundle > 0 && Status == 'ACTIVE' && product.InventoryStatus == 'LOW_STOCK'" class="inventory-status low-stock">{{ $t.getTranslation("LBL_OUT_OF_STOCK") }}</div>

  <!-- <div v-if="showqty" style="color:#ccc;display: block;">{{ $t.getTranslation("LBL_REMAINING_STOCK") }}: {{ product.SellableQty }}</div> -->
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ProductToolbarComponent",
  components: {},
  props: { product: Object, showqty: Boolean },
  setup(props) {
    let Status = ref(props?.product.ProductStatus || props.product?.StatusCode);
    return {
      Status,
    };
  },
});
</script>

<style>
.inventory-status {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
}

.inventory-status.out-of-stock {
  color: red;
}

.inventory-status.in-stock {
  color: green;
}

.inventory-status.low-stock {
  color: orange;
}
</style>
